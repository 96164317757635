
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


html,
body,
#root {
	height: 100%;
	min-height: 600px;
	min-width: 1130px;

	background-color: rgb( 163, 208, 211 );

	text-align: center;
	font-size: 12px;
	letter-spacing: .15em;

	overflow: clip;
}

a, a:visited {
	text-decoration: none;
}

hr {
	border: 0;
	height: 0;
	border-top: 1px rgba( 255, 255, 255, .5 ) solid;
}

:where(ul[class]) {
	margin: 0;
	padding: 0;
	list-style-type: none;
}






.map-container {
  height: 400px;
  width: 400px;
}
 
.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #ffffff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 12px;
border-radius: 4px;
}

/* Mapbox */

.mapboxgl-ctrl-logo {
	display: none !important;
}


/* Nav Control */
.mapboxgl-ctrl-bottom-right {
    bottom: 25px !important;
}
.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none !important;
}
.mapboxgl-ctrl-group {
    /* border-radius: 4px; */
    background: transparent !important;
}

.mapboxgl-ctrl-group button {
	width: 70px !important;
	height: 70px !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.04495 62.60224'%3E%3Cg%3E%3Cg%3E%3Cpath d='M6.12926,18.35827l-5.972,20.6752a4.00329,4.00329,0,0,0,0,2.22185l5.972,20.6752a.92973.92973,0,0,0,1.78643,0l5.972-20.6752a4.00329,4.00329,0,0,0,0-2.22185l-5.972-20.6752A.92973.92973,0,0,0,6.12926,18.35827Z' fill='%23deaf5f'/%3E%3Ccircle cx='7.02248' cy='40.14439' r='2.01814' fill='%23ecebe5'/%3E%3C/g%3E%3Cpath d='M2.14334,0h.82183L11.0634,10.21083V0h.83821V11.73642h-.65392L2.98156,1.30784V11.73642H2.14334Z' fill='%23454545'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
}